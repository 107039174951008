import './index.scss'
import Swiper from 'swiper/swiper-bundle.min.js'
$("header .nav li").eq(0).addClass("active");
$("header").addClass("head1");
$("header").removeClass("on");

var swiper = new Swiper(".index-banner .mySwiper", {
    observer:true,//修改swiper自己或子元素时，自动初始化swiper
    observeParents:true,//修改swiper的父元素时，自动初始化swiper
    loop:true,
    speed:2000,
    autoplay: {
        delay: 3000,
    },
    pagination: {
        el: '.index-banner .swiper-page',
        clickable: true,
		renderBullet: function (index, className) {
			return '<span class="' + className + '">' + "</span>";
		},
    },
});


$(".product-nav ul li").eq(0).addClass("active")
$(".product-list ul").eq(0).addClass("active")
$(".product-nav ul li").hover(function () {
    let index = $(this).index()
    $(this).addClass("active").siblings().removeClass("active");
    $(".product-list ul").eq(index).addClass("active").siblings().removeClass("active");
})

$(".section2a ul li").eq(0).addClass("active");
$(".section2a ul li").hover(function () {
    $(this).addClass("active").siblings().removeClass("active");
})

let h2_max = 0; //定义一个默认变量，并赋值为0，用于比较	
$('.section3 ul li h2').each(function() { //遍历相同样式名的元素
    let h2 = $(this).height(); //获取每个元素的高度，（可根据需要使用outerHeight、innerHeight）
    h2_max = h2 > h2_max ? h2 : h2_max; //比较获取的元素高度值和默认变量，如果比0大，则取获取的值，否则取0
});
$('.section3 ul li h2').height(h2_max); //给每一个元素设置相同的高度	

let p_max = 0; //定义一个默认变量，并赋值为0，用于比较	
$('.section3 ul li p').each(function() { //遍历相同样式名的元素
    let p = $(this).height(); //获取每个元素的高度，（可根据需要使用outerHeight、innerHeight）
    p_max = p > p_max ? p : p_max; //比较获取的元素高度值和默认变量，如果比0大，则取获取的值，否则取0
});
$('.section3 ul li p').height(p_max); //给每一个元素设置相同的高度	

$(".play").click(function () {
    var video = document.getElementById('myVideo');
    if (video.paused) { //如果已暂停则播放
        $('.play').hide();
        video.play(); //播放控制
    } else { // 已播放点击则暂停
        $('.play').show();
        video.pause(); //暂停控制
    }
})